<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="order_sn"
          label="订单号"
          align="left">
      </el-table-column>
      <el-table-column
          prop="user_id"
          label="会员ID"
          width="200">
      </el-table-column>
      <el-table-column
          prop="name"
          label="昵称"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="avatar"
          label="头像"
          align="center"
          width="160">
        <template slot-scope="scope">
          <el-avatar :size="40" shape="square" :src="scope.row.avatar"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="提现金额(含手续费)"
          align="center"
          width="150">
        <template slot-scope="scope">
          {{scope.row.amount}}
        </template>
      </el-table-column>
      <el-table-column
          prop="fee"
          label="手续费"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="申请时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          label="状态"
          align="center"
          width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0">待审核</div>
          <div v-if="scope.row.status==1">打款中</div>
          <div v-if="scope.row.status==2">已打款</div>
          <div v-if="scope.row.status==3">失败：{{scope.row.remark}}</div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showBox(scope.row)" v-if="scope.row.status==0">审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="审核提现"
        :visible.sync="showDialog"
        :destroy-on-close="true"
        width="600px">
      <el-form :model="shenheInfo" label-position="right" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="shenheInfo.type">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" v-if="shenheInfo.type==2">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入原因"
              v-model="shenheInfo.remark">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="shenheWith">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      shenheInfo: {
        id: 0,
        type: '',
        remark: '',
      },
      showDialog: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    showBox(row) {
      this.shenheInfo.id = row.id
      this.shenheInfo.type = ''
      this.shenheInfo.remark = ''
      this.showDialog = true
    },
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords
      }
      this.$api.member.memberWithIndex(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    shenheWith() {
      if(this.shenheInfo.type == '') {
        this.fail('请选择类型')
        return false
      }
      if(this.shenheInfo.type==2 && this.shenheInfo.remark=='') {
        this.fail('请输入拒绝原因')
        return false
      }
      this.$api.member.memberWithShenhe(this.shenheInfo, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.getList()
          this.showDialog = false
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
